import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import Bannerfrom from "../components/bannerfrom";
import HtmlParser from "react-html-parser";

function Banner({data}) {
    return (

        <div className="services_banner">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={7} xl={7} xxl={7} className="my-auto">
                        <div className="banenr_content">
                            <div className="example" data-text={data?.banner_body_txt}>
                                <h1>{HtmlParser(data?.banner_title)}</h1>
                            </div>
                            <div
                                className="banner_content_txt listing_flex">{HtmlParser(data?.banner_text)}</div>

                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto ms-auto">
                        <Bannerfrom title={`Book a Free <span>Consultation</span>`}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;