import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from "react-html-parser";

function Languagedevelopment({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.tech_dev_body_txt}>
                            <h2>{HtmlParser(data?.tech_dev_title)}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(data?.tech_dev_description)}</div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Languagedevelopment;