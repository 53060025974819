import React, {useEffect, useState} from 'react';
import Breadcrumbs from "../profile/breadcrumbs";
import Featured from "../home/featured";
import Partner from "../home/partner";
import Layout from "../layout/layout";
import Languagedevelopment from "./languagedevelopment";
import Hiredeveloper from "./hiredeveloper";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import Seo from "../layout/seo";
import {checkData} from "../../helper";
import Developmentprocess from "../services/developmentprocess";
import Loader from "../components/Loader";
import Banner from "./banner";


function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/language/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);


    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="Technology" link2="Language" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Featured/>
                    <Languagedevelopment data={userData}/>
                    <Hiredeveloper data={userData}/>
                    <Developmentprocess data={userData}/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;